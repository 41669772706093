import { Expose } from 'class-transformer';

export class UserInfo {
  @Expose()
  id: string;

  @Expose()
  email: string;

  @Expose({ name: 'first_name' })
  firstName: string;

  @Expose({ name: 'last_name' })
  lastName: string;

  @Expose()
  language: string;

  @Expose({ name: 'seller_id' })
  sellerId: string;

  @Expose({ name: 'retail_group_id' })
  groupId: string | null;

  @Expose({ name: 'store_analytics_template' })
  theme: string;

  @Expose({ name: 'reporting_api_allow_current_day' })
  allowCurrentDay: boolean;

  @Expose({ name: 'organization_id' })
  organizationId: string;

  @Expose({ name: 'operation_ids' })
  operationIds: string[];

  @Expose({ name: 'admin_access' })
  adminAccess: boolean;

  @Expose({ name: 'installer_access' })
  installerAccess: boolean;

  @Expose({ name: 'seller_access' })
  sellerAccess: boolean;

  @Expose({ name: 'manager_access' })
  managerAccess: boolean;

  @Expose({ name: 'retail_group_access' })
  groupAccess: boolean;

  @Expose({ name: 'store_analytics_access' })
  analyticsAccess: boolean;
}
