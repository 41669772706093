import * as Highcharts from 'highcharts';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRootComponent } from './app-root/app-root.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { SharedModule } from './modules/shared/shared.module';
import { JwtInterceptor } from './services/jwt.interceptor';
import { TokenInterceptor } from './services/token.interceptor';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { APP_BASE_HREF } from '@angular/common';
import { GoogleAnalyticsService, NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { LanguageService } from './services/language/language.service';
import { MetricGroupService } from './modules/admin/services/metric-groups/metric-group.service';
import { MetricGroupsStore } from './modules/admin/services/metric-groups/metric-groups.store';
import { MetricGroupQuery } from './modules/admin/services/metric-groups/metric-group.query';
import { AppDirectivesModule } from './modules/directives/app-directives.module';
import { Theme, ThemeService } from './modules/shared/theme.service';
import { matRed } from './modules/shared/color-palette';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, AppRootComponent, NotFoundComponent],
  imports: [
    HttpClientModule,
    SharedModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AuthenticationModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production || environment.development }),
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule,
    AppDirectivesModule
  ],
  exports: [
    RouterModule // Tests needs this to be exported
  ],
  bootstrap: [AppRootComponent],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    MetricGroupService,
    MetricGroupQuery,
    MetricGroupsStore,

    GoogleAnalyticsService
  ]
})
export class AppModule {
  constructor(themeService: ThemeService, _: LanguageService) {
    themeService.addTheme('pygmalios', {
      chartColorRange: ['#E3D8F8', '#602BC6']
    });

    themeService.addTheme('a1', {
      chartColorRange: [matRed[50], matRed[900]]
    });

    themeService.addTheme('a1hr', {
      chartColorRange: [matRed[50], matRed[900]]
    });

    // Set colors for charts
    themeService.theme$.subscribe((theme: Theme) => {
      Highcharts.setOptions({
        chart: {
          styledMode: true
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        colorAxis: {
          min: 1,
          minColor: theme.chartColorRange[0],
          maxColor: theme.chartColorRange[1]
        }
      });
    });

    themeService.setTheme('pygmalios');
  }
}
