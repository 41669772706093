import { Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { UserInfoService } from './user-info/user-info.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private refreshToken$ = new Subject<null>();
  private isRefreshing = false;

  constructor(private auth: AuthService, private userInfoService: UserInfoService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handleUnauthorizedRequest(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handleUnauthorizedRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      return this.auth.refresh().pipe(
        filter(x => x === true),
        switchMap(() => {
          this.isRefreshing = false;
          this.refreshToken$.next(null);
          return next.handle(this.addToken(request, this.auth.token));
        })
      );
    } else {
      return this.refreshToken$.pipe(
        take(1),
        switchMap(() => {
          return next.handle(this.addToken(request, this.auth.token));
        })
      );
    }
  }
}
