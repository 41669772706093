import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
import { plainToClass } from 'class-transformer';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { UserInfo } from './user-info';
import { UserInfoQuery } from './user-info.query';
import { UserInfoStore } from './user-info.store';
import { TranslateService } from '@ngx-translate/core';
import { getErrorMessage } from '../../modules/shared/get-error-mesage';

@Injectable({ providedIn: 'root' })
export class UserInfoService {
  constructor(
    private infoStore: UserInfoStore,
    private infoQuery: UserInfoQuery,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  getUserInfo() {
    return this.infoQuery.selectHasCache().pipe(
      switchMap(hasCache => {
        this.infoStore.setLoading(true);
        const request$ = this.http.get<any>(`${environment.directoryApi}/user`).pipe(
          map(x => plainToClass(UserInfo, x, { excludeExtraneousValues: true })),
          tap(x => {
            this.infoStore.update(x);
            this.infoStore.setLoading(false);
          }),
          catchError(err => of(this.snackBar.open(getErrorMessage(err), 'OK', { panelClass: 'red-snackbar' })))
        );

        return hasCache ? EMPTY : request$;
      })
    );
  }

  refreshCache() {
    this.infoStore.setHasCache(false);
  }

  async update(firstName: string, lastName: string, language: string) {
    try {
      const res = await this.http
        .patch<any>(
          `${environment.directoryApi}/user`,
          {
            first_name: firstName,
            last_name: lastName,
            language
          },
          { observe: 'response' }
        )
        .toPromise();

      if (res.status === 200) {
        // TODO translate this.snackBar.open('User edited succesfully', 'OK', { panelClass: 'green-snackbar' });
        const userInfo = plainToClass(UserInfo, res.body, { excludeExtraneousValues: true });
        this.infoStore.update(userInfo);
      }
    } catch (err) {
      this.snackBar.open(getErrorMessage(err), 'OK', { panelClass: 'red-snackbar' });
      this.infoStore.setError(err.statusText);
      throw err;
    }
  }

  async changePassword(oldPassword: string, newPassword: string) {
    try {
      await this.http
        .patch<any>(`${environment.directoryApi}/user`, {
          old_password: oldPassword,
          password: newPassword
        })
        .toPromise();

      this.snackBar.open(this.translate.instant('settings.password_successfully_changed'), 'OK', {
        panelClass: 'green-snackbar'
      });
    } catch (err) {
      let error = 'Something went wrong';
      if (err.status === 422) {
        error = this.translate.instant('settings.invalid_old_password');
      }

      this.snackBar.open(error, 'OK', { panelClass: 'red-snackbar' });
    }
  }
}
