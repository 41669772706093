import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { MetricGroupsState, MetricGroupsStore } from './metric-groups.store';

@Injectable()
export class MetricGroupQuery extends QueryEntity<MetricGroupsState> {
  constructor(protected store: MetricGroupsStore) {
    super(store);
  }
}
