<ng-container *ngIf="!isLoaded; else loaded">
  <div class="loading">
    <div class="modal">
      <mat-spinner></mat-spinner>
      <p>Loading...</p>
    </div>
  </div>
</ng-container>

<ng-template #loaded>
  <div
    class="menu"
    [class.menu-close]="!sidebar.isOpen"
    [style.position]="sidebar.isPin ? 'sticky' : 'absolute'"
    (mouseleave)="closeSidebar()"
  >
    <ui-menu (mouseenter)="openSidebar()">
      <ng-container ui-header>
        <ng-container *ngIf="theme === 'a1' || theme === 'a1hr'">
          <img src="/assets/a1/A1_logo.jpg" class="logo-a1" />
          <span class="description">Visitor Analytics</span>
        </ng-container>
        <ng-container *ngIf="theme === 'pygmalios'">
          <img src="/assets/logo.svg" class="logo" />
        </ng-container>
      </ng-container>
      <ng-container #submenu></ng-container>
      <ng-container ui-footer>
        <!-- <ui-menu-entry link="/settings" icon="cog-outline" name="settings.label"></ui-menu-entry> -->

        <ng-container *ngIf="isInAdminConsole; else goToAdminConsole">
          <ui-menu-entry link="/analytics/visits" icon="trending-down" name="Analytics"></ui-menu-entry>
        </ng-container>
        <ng-template #goToAdminConsole>
          <ui-menu-entry
            link="/admin"
            icon="account-cog-outline"
            name="Admin"
            *pygPermissions="'admin'"
          ></ui-menu-entry>
          <ui-menu-entry link="/analytics/settings" icon="cog" name="settings.label"></ui-menu-entry>
        </ng-template>

        <ui-menu-entry (click)="logout()" icon="logout" name="logout.label"></ui-menu-entry>
      </ng-container>
    </ui-menu>

    <button mat-mini-fab color="basic" class="sidebar-pin-btn" (click)="tooglePinSidebar()">
      <mat-icon *ngIf="sidebar.isPin">keyboard_arrow_left</mat-icon>
      <mat-icon *ngIf="!sidebar.isPin">keyboard_arrow_right</mat-icon>
    </button>
  </div>
  <div class="container" [style.margin-left]="sidebar.isPin ? '0' : '40px'">
    <router-outlet (activate)="onActivate($event)"></router-outlet>

    <!-- <div class="footer">
      © 2020 Pygmalios | <a href="">Privacy Policy</a> | <a href="">Terms of Service</a> | <a href="">Send Feedback</a>
    </div> -->
  </div>
</ng-template>
