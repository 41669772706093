import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { startWith } from 'rxjs';
import { SidebarService } from '../../sidebar.service';

export interface SubMenu {
  name: string;
  icon: string;
  link: string;
}

@Component({
  selector: 'ui-menu-entry',
  templateUrl: './ui-menu-entry.component.html',
  styleUrls: ['./ui-menu-entry.component.scss']
})
export class UiMenuEntryComponent implements OnInit {
  @Input() name: string;
  @Input() icon: string;
  @Input() link: string;
  @Input() badge: string;
  @Input() submenu: SubMenu[];

  @HostBinding('class.active') active = false;

  constructor(private router: Router, public sidebar: SidebarService) {}

  ngOnInit() {
    this.router.events.pipe(startWith(new NavigationStart(0, this.router.url))).subscribe(event => {
      if (event instanceof NavigationStart || event instanceof NavigationEnd) {
        this.active =
          event.url.startsWith(this.link) && [undefined, '/', '?', '#'].includes(event.url[this.link?.length]);
      }
    });
  }

  navigate() {
    if (this.link) {
      this.router.navigate([this.link], {
        preserveFragment: true
      });
    }
  }
}
