import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { NoAuthenticationGuard } from '../../services/no-authentication-guard';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [NoAuthenticationGuard],
    component: LoginComponent
  },
  { path: '**', redirectTo: '/auth/login' }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes), TranslateModule.forChild()],
  declarations: [LoginComponent],
  providers: []
})
export class AuthenticationModule {
  static forRoot(): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule
    };
  }
}
