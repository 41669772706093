import { AuthService } from '../../../services/auth.service';

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'apps/trix/src/environments/environment';

@Component({
  selector: 'pyg-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isLoading = false;
  year = new Date().getFullYear();
  isDemoEnv = environment.isDemo;

  model: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email, this.isDemoValidator()]),
    password: new FormControl(null, [Validators.required])
  });

  constructor(private auth: AuthService, private router: Router) {}

  get showErr() {
    const m = this.model.controls.email;

    if (m.hasError('required')) {
      return 'required';
    } else if (m.hasError('email')) {
      return 'email';
    } else if (m.hasError('notAllowedEmail')) {
      return 'notAllowedEmail';
    }
  }

  ngOnInit() {}

  async login() {
    if (!this.model.valid) {
      this.model.markAllAsTouched();
      return false;
    }

    this.isLoading = true;
    const { email, password } = this.model.value;
    const response = await this.auth.login(email, password);
    if (!response) {
      this.model.controls.password.setErrors({ wrongPassword: true });
    }

    this.isLoading = false;
    this.router.navigateByUrl('/');
  }

  private isDemoValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isDemoAccount = control.value === 'demo@pygmalios.com';
      return (isDemoAccount && !this.isDemoEnv) || (!isDemoAccount && this.isDemoEnv)
        ? { notAllowedEmail: true }
        : null;
    };
  }
}
