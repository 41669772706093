import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UserInfo } from './user-info';
import { UserInfoStore } from './user-info.store';

@Injectable({ providedIn: 'root' })
export class UserInfoQuery extends Query<UserInfo> {
  language$ = this.select(x => x.language);
  theme$ = this.select(x => x.theme);
  allowCurrentDay$ = this.select(x => x.allowCurrentDay);
  isAdmin$ = this.select(x => x.adminAccess);

  constructor(protected store: UserInfoStore) {
    super(store);
  }
}
