import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import * as HighchartsBoost from 'highcharts/modules/boost';
import addHeatmapModule from 'highcharts/modules/heatmap';
import addSankeyModule from 'highcharts/modules/sankey';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { MdePopoverModule } from '@material-extended/mde';
import { TranslateModule } from '@ngx-translate/core';

import { UiCardComponent } from './components/ui-card/ui-card.component';
import { UiColumnChartComponent } from './components/ui-column-chart/ui-column-chart.component';
import { UiDialogActionsComponent } from './components/ui-dialog-actions/ui-dialog-actions.component';
import { UiDialogContentComponent } from './components/ui-dialog-content/ui-dialog-content.component';
import { UiDialogHeaderComponent } from './components/ui-dialog-header/ui-dialog-header.component';
import { UiDialogComponent } from './components/ui-dialog/ui-dialog.component';
import { UiHeatmapChartComponent } from './components/ui-heatmap-chart/ui-heatmap-chart.component';
import { UiInputComponent } from './components/ui-input/ui-input.component';
import { UiLineChartComponent } from './components/ui-line-chart/ui-line-chart.component';
import { UiMenuEntryComponent } from './components/ui-menu-entry/ui-menu-entry.component';
import { UiMenuComponent } from './components/ui-menu/ui-menu.component';
import { UiPieChartComponent } from './components/ui-pie-chart/ui-pie-chart.component';
import { UiRowComponent } from './components/ui-row/ui-row.component';
import { UiSparklineChartComponent } from './components/ui-sparkline-chart/ui-sparkline-chart.component';
import { UiUploadComponent } from './components/ui-upload/ui-upload.component';
import { UiYesNoDialogComponent } from './components/ui-yes-no-dialog/ui-yes-no-dialog.component';
import { DialogService } from './dialog.service';
import { DomSanitizer } from '@angular/platform-browser';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportData from 'highcharts/modules/export-data';
import { UiLoadingComponent } from './components/ui-loading/ui-loading.component';
import { UiMaxLineChartComponent } from './components/ui-max-line-chart/ui-max-line-chart.component';
import { UiBreadcrumbComponent } from './components/ui-breadcrumb/ui-breadcrumb.component';
import { UiSelectorComponent } from './components/ui-selector/ui-selector.component';
import { UiInputSelectorComponent } from './components/ui-input-selector/ui-input-selector.component';
import { UiSankeyComponent } from './components/ui-sankey/ui-sankey.component';
import { UiFloormapComponent } from './components/ui-floormap/ui-floormap.component';
import { UiFilterComponent } from './components/ui-filter/ui-filter.component';
import { UiMegaHeatmapChartComponent } from './components/ui-mega-heatmap-chart/ui-mega-heatmap-chart.component';
import { UiHeatmapComponent } from './components/ui-heatmap/ui-heatmap.component';
import { ClipboardModule } from '@angular/cdk/clipboard';

HC_exporting(Highcharts);
HC_exportData(Highcharts);
addHeatmapModule(Highcharts);
addSankeyModule(Highcharts);
(HighchartsBoost as any)(Highcharts);

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forChild(),
    RouterModule,

    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatTreeModule,
    MatToolbarModule,
    MatTooltipModule,
    MdePopoverModule,
    ScrollingModule,
    ClipboardModule
  ],
  declarations: [
    UiMenuComponent,
    UiMenuEntryComponent,
    UiRowComponent,
    UiDialogComponent,
    UiDialogHeaderComponent,
    UiDialogContentComponent,
    UiDialogActionsComponent,
    UiInputComponent,
    UiYesNoDialogComponent,
    UiCardComponent,
    UiSparklineChartComponent,
    UiHeatmapChartComponent,
    UiMegaHeatmapChartComponent,
    UiPieChartComponent,
    UiColumnChartComponent,
    UiLineChartComponent,
    UiMaxLineChartComponent,
    UiUploadComponent,
    UiLoadingComponent,
    UiBreadcrumbComponent,
    UiSelectorComponent,
    UiInputSelectorComponent,
    UiSankeyComponent,
    UiFloormapComponent,
    UiFilterComponent,
    UiHeatmapComponent
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,

    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatTreeModule,
    MatToolbarModule,
    MatTooltipModule,
    MdePopoverModule,
    ScrollingModule,

    UiMenuComponent,
    UiMenuEntryComponent,
    UiRowComponent,
    UiDialogComponent,
    UiDialogHeaderComponent,
    UiDialogContentComponent,
    UiDialogActionsComponent,
    UiInputComponent,
    UiYesNoDialogComponent,
    UiCardComponent,
    UiSparklineChartComponent,
    UiHeatmapChartComponent,
    UiMegaHeatmapChartComponent,
    UiPieChartComponent,
    UiColumnChartComponent,
    UiLineChartComponent,
    UiMaxLineChartComponent,
    UiUploadComponent,
    UiLoadingComponent,
    UiBreadcrumbComponent,
    UiSelectorComponent,
    UiInputSelectorComponent,
    UiSankeyComponent,
    UiFloormapComponent,
    UiFilterComponent,
    UiHeatmapComponent
  ],
  providers: [DialogService, { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 10000 } }]
})
export class SharedModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    moment.relativeTimeThreshold('s', 60);
    moment.relativeTimeThreshold('m', 60);
    moment.relativeTimeThreshold('h', 24);
    moment.relativeTimeThreshold('d', 31);
    moment.relativeTimeThreshold('M', 12);
    moment.relativeTimeThreshold('y', 365);

    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
  }

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [{ provide: DateAdapter, useClass: MomentDateAdapter }]
    };
  }
}
