<div class="header">
  <ng-content select="[ui-header]"></ng-content>
</div>

<div class="menu">
  <ng-content></ng-content>
</div>

<div class="footer">
  <ng-content select="[ui-footer]"></ng-content>
</div>
