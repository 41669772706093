import { HttpErrorResponse } from '@angular/common/http';

export function getErrorMessage(err: HttpErrorResponse): string {
  if (err.error?.hasOwnProperty('message')) {
    let parsedMessage: any;

    try {
      parsedMessage = JSON.parse(err.error.message);
    } catch (e) {
      parsedMessage = err.error.message;
    }

    return `Server responded: ${parsedMessage.message ? parsedMessage.message : parsedMessage}`;
  } else if (err.error?.hasOwnProperty('errors')) {
    let isExports = err.url.includes('data_exports');
    if (isExports) {
      return `Server responded: ${err.error.errors
        .map(err => {
          return err.description;
        })
        .join(', ')}`;
    } else if (Array.isArray(err.error.errors) && err.error.errors.every(err => err.title)) {
      const errs: any[] = err.error.errors;
      return `${errs.map(err => err.title.charAt(0).toUpperCase() + err.title.slice(1)).join('. ')}`;
    } else {
      return `Error occurred with: ${Object.keys(err.error.errors).join(', ')}`;
    }
  } else if (err.error?.hasOwnProperty('title')) {
    return `Server responded: ${err.error.title}`;
  } else {
    let errors: any;

    try {
      errors = JSON.parse(err.error)?.errors;
    } catch (e) {
      errors = null;
    }

    if (errors?.length > 0) {
      return `Server responded: ${JSON.parse(err.error)
        .errors.map(err => {
          return err.description;
        })
        .join(', ')}`;
    } else if (err.message) {
      return `Server responded: ${err.message}`;
    } else {
      return 'Something went wrong';
    }
  }
}
