import { Injectable } from '@angular/core';
import { filterNil } from '@datorama/akita';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { StyleManagerService } from './style-manager.service';

export interface Theme {
  chartColorRange: string[];
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private _currentTheme = new BehaviorSubject<string>(null);
  private _themes = {};

  theme$ = this._currentTheme.pipe(
    map(name => this._themes[name]),
    filterNil
  );

  isTheme(name: string) {
    return this._currentTheme.pipe(map(x => x === name));
  }

  constructor(private styleManager: StyleManagerService) {}

  addTheme(name: string, theme: Theme) {
    this._themes[name] = theme;
  }

  setTheme(name: string) {
    if (!this._themes[name]) {
      throw 'invalid theme ' + name;
    }

    this._currentTheme.next(name);
    this.styleManager.setStyle('theme', `theme-${name}.css`);
  }
}
