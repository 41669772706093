import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { BehaviorSubject } from 'rxjs';
import { Fetch } from './fetch';

@Injectable({
  providedIn: 'root'
})
export class FetchCacheService {
  private db: any;
  private currentlyLoading: any = {};
  private _droppedDb = new BehaviorSubject<null>(null);

  get droppedDatabase$() {
    return this._droppedDb.asObservable();
  }

  constructor() {
    this.db = new Dexie('fetch-cache');
    this.db.version(1).stores({
      data: '[metrics+dimensions+filterHash+startDate+endDate+previousStartDate+previousEndDate]'
    });

    this.db.open().catch(error => {
      alert('Errod during connecting to database : ' + error);
    });
  }

  addData(fetch: Fetch, data: any) {
    console.log('adding to cache');

    return this.db.data.add({
      dimensions: fetch.dimensions,
      metrics: fetch.metrics,
      filterHash: JSON.stringify(fetch.filters),
      filters: fetch.filters,
      startDate: fetch.startDatetime.format('YYYY-MM-DD'),
      endDate: fetch.endDatetime.format('YYYY-MM-DD'),
      previousStartDate: fetch.previousPeriod ? fetch.previousPeriod.startDatetime.format('YYYY-MM-DD') : '',
      previousEndDate: fetch.previousPeriod ? fetch.previousPeriod.endDatetime.format('YYYY-MM-DD') : '',
      data
    });
  }

  findCache(fetch: Fetch) {
    try {
      return this.db.data.get({
        dimensions: fetch.dimensions,
        metrics: fetch.metrics,
        filterHash: JSON.stringify(fetch.filters),
        startDate: fetch.startDatetime.format('YYYY-MM-DD'),
        endDate: fetch.endDatetime.format('YYYY-MM-DD'),
        previousStartDate: fetch.previousPeriod ? fetch.previousPeriod.startDatetime.format('YYYY-MM-DD') : '',
        previousEndDate: fetch.previousPeriod ? fetch.previousPeriod.endDatetime.format('YYYY-MM-DD') : ''
      });
    } catch (e) {
      console.warn(e);
      return null;
    }
  }

  getLoading(fetch: Fetch) {
    return this.currentlyLoading[JSON.stringify(fetch)];
  }

  addLoading(fetch: Fetch, promise: Promise<any>) {
    this.currentlyLoading[JSON.stringify(fetch)] = promise;
  }

  removeLoading(fetch: Fetch) {
    this.currentlyLoading[JSON.stringify(fetch)] = undefined;
  }

  async dropDatabase() {
    await this.db.data.clear();
    this._droppedDb.next(null);
  }
}
