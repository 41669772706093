import 'hammerjs';
import * as Sentry from '@sentry/angular-ivy';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment as env } from './environments/environment';
import packageInfo from '../../../package.json';
import { akitaConfig, enableAkitaProdMode } from '@datorama/akita';
import { persistState } from '@datorama/akita';
import localeSk from '@angular/common/locales/sk';
import { registerLocaleData } from '@angular/common';

if (env.sentry) {
  Sentry.init({
    dsn: env.sentry,
    release: packageInfo.name + '@' + packageInfo.version,
    environment: (env.isDemo ? 'Demo' : 'App') + '@' + (env.production ? 'Prod' : 'Dev')
  });
}

const storage = persistState({
  persistOnDestroy: false
});

const providers = [{ provide: 'persistStorage', useValue: storage }];
akitaConfig({ resettable: true });

if (env.production) {
  enableProdMode();
  enableAkitaProdMode();
}

registerLocaleData(localeSk);

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
