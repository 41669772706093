import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { UserInfoQuery } from './user-info/user-info.query';

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
  constructor(private uiQuery: UserInfoQuery) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.uiQuery.getValue();

    if (user.adminAccess) {
      return true;
    }

    // Log to sentry
    throw `Unauthorized access! User ${user.email}`;
  }
}
