<div (click)="navigate()" class="link" [style]="sidebar.isOpen ? 'width:100%;' : 'width:0; opacity:0'">
  <div class="icon"><mat-icon [svgIcon]="icon || 'error'"></mat-icon></div>
  <div class="label" *ngIf="badge; else nonBadge">
    {{ name | translate }}
    <div class="badge">{{ badge }}</div>
  </div>

  <ng-template #nonBadge>
    <div class="label">{{ name | translate }}</div>
  </ng-template>
</div>

<div class="submenu">
  <div *ngFor="let m of submenu">
    {{ m.name }}
  </div>
  <ng-content></ng-content>
</div>
