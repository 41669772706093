import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { LocalStorage } from '../modules/shared/local-storage';
import { FetchCacheService } from './fetch-cache.service';
import { resetStores } from '@datorama/akita';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  @LocalStorage private _token: string = null;
  @LocalStorage private refreshToken: string = null;
  @LocalStorage private expiration: number = null;

  private _payload = new BehaviorSubject(null);
  private _isLogged = new BehaviorSubject(this.token != null);

  timeoutId: any;

  constructor(private injector: Injector, private fcService: FetchCacheService, private dialogRef: MatDialog) {
    this.setTimer();

    if (this._token) {
      this._setPayload(this._token);
    }
  }

  get isLogged$() {
    return this._isLogged.asObservable();
  }

  get isLogged() {
    return this._isLogged.value;
  }

  get token() {
    return this._token;
  }

  get payload() {
    return this._payload.value;
  }

  get payload$() {
    return this._payload;
  }

  setToken(token: string) {
    this._token = token;
    this._isLogged.next(token != null);
    this._setPayload(token);
  }

  private _setPayload(token: string) {
    const helper = new JwtHelperService();
    const payload = helper.decodeToken(token);
    this._payload.next(payload);
  }

  get permissions() {
    return this.payload.permissions;
  }

  async login(username: string, password: string): Promise<boolean> {
    try {
      // Ignore HTTP Interceptors
      const response = (await this.getCleanClient()
        .post(`${environment.authApi}/oauth/token`, {
          client_id: environment.clientId,
          grant_type: 'password',
          username,
          password
        })
        .toPromise()) as any;

      resetStores();
      this.refreshToken = response.refresh_token;
      this.setToken(response.access_token);
      this.expiration = response.created_at + response.expires_in;
      this.setTimer();
    } catch (e) {
      console.log('err', e);
      this.clearAll();

      return false;
    }

    return true;
  }

  refresh(): Observable<boolean> {
    const refreshed = new BehaviorSubject<boolean>(false);
    this.getCleanClient()
      .post<any>(`${environment.authApi}/oauth/token`, {
        client_id: environment.clientId,
        grant_type: 'refresh_token',
        refresh_token: this.refreshToken
      })
      .subscribe(
        response => {
          this.refreshToken = response.refresh_token;
          this.setToken(response.access_token);
          this.expiration = response.created_at + response.expires_in;
          refreshed.next(true);
          this.setTimer();
        },
        error => {
          this.clearAll();
          refreshed.next(false);
        }
      );

    return refreshed;
  }

  async logout() {
    try {
      await this.getCleanClient()
        .post(`${environment.authApi}/oauth/revoke`, {
          client_id: environment.clientId,
          auth_token: this.token
        })
        .toPromise();
    } catch (e) {
      console.error(e);
    }

    this.clearAll();
  }

  private setTimer() {
    if (this.expiration == null) {
      return;
    }

    const expiration = this.expiration - new Date().getTime() / 1000 - 10;
    console.log('[Auth Service] Token will expire in:', expiration);
    if (expiration <= 0) {
      console.log('less than');
      this.refresh();
      return;
    }

    this.timeoutId = setTimeout(() => {
      this.refresh();
    }, expiration * 1000);
  }

  private clearAll() {
    this.refreshToken = null;
    this.expiration = null;
    this.setToken(null);
    clearTimeout(this.timeoutId);
    this.dialogRef.closeAll();

    localStorage.clear();
    this.fcService.dropDatabase();
  }

  private getCleanClient() {
    const backend = this.injector.get(HttpBackend);
    return new HttpClient(backend);
  }
}
