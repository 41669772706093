import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Organization } from './organization';

export interface OrganizationTreeState extends EntityState<Organization, string>, ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'organization-tree'
})
export class OrganizationTreeStore extends EntityStore<OrganizationTreeState> {
  constructor() {
    super();
  }
}
