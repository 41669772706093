import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-menu',
  templateUrl: './ui-menu.component.html',
  styleUrls: ['./ui-menu.component.scss']
})
export class UiMenuComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
