<div class="frame">
  <div class="login">
    <img src="/assets/login/logo.png" class="logo" />
    <span class="name">Store Analytics</span>
    <mat-progress-bar mode="indeterminate" class="loading" *ngIf="isLoading"></mat-progress-bar>
    <form [formGroup]="model">
      <mat-form-field>
        <input matInput formControlName="email" />
        <mat-label>E-mail</mat-label>
        <mat-error *ngIf="showErr === 'required'"> E-mail is required! </mat-error>
        <mat-error *ngIf="showErr === 'email'">Invalid E-mail entered!</mat-error>
        <mat-error *ngIf="showErr === 'notAllowedEmail'">
          E-mail is not allowed for {{ isDemoEnv ? 'demo' : 'app' }}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput type="password" formControlName="password" />
        <mat-label>Password</mat-label>
        <mat-error *ngIf="model.controls.password.hasError('required')"> Password is required! </mat-error>
        <mat-error *ngIf="model.controls.password.hasError('wrongPassword')"> Wrong E-mail or password! </mat-error>
      </mat-form-field>

      <div class="actions">
        <button mat-raised-button type="submit" color="primary" (click)="login()">Login</button>
      </div>

      <div class="copyright">© {{ year }} Pygmalios. All rights reserved</div>
    </form>
  </div>
  <div class="info"></div>
</div>
