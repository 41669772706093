import { Directive, TemplateRef, EmbeddedViewRef, ViewContainerRef, Input, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserInfo } from '../../services/user-info/user-info';
import { UserInfoQuery } from '../../services/user-info/user-info.query';

class Context {
  public hasPermissions = false;
}

@UntilDestroy()
@Directive({
  selector: '[pygPermissions]'
})
export class PermissionsDirective {
  private _context: Context = new Context();
  private _thenTemplateRef: TemplateRef<Context> | null = null;
  private _elseTemplateRef: TemplateRef<Context> | null = null;
  private _thenViewRef: EmbeddedViewRef<Context> | null = null;
  private _elseViewRef: EmbeddedViewRef<Context> | null = null;

  constructor(
    private infoQuery: UserInfoQuery,
    private _viewContainer: ViewContainerRef,
    templateRef: TemplateRef<Context>
  ) {
    this._thenTemplateRef = templateRef;
  }

  @Input()
  set pygPermissions(permissions: string[] | string) {
    this.infoQuery
      .select()
      .pipe(untilDestroyed(this))
      .subscribe(userInfo => {
        let hasPerms = false;

        if (Array.isArray(permissions)) {
          const perms = permissions.map(x => this.permissionsCheck(userInfo, x));
          hasPerms = perms.includes(true);
        } else {
          hasPerms = this.permissionsCheck(userInfo, permissions as string);
        }

        this._context.hasPermissions = hasPerms;
        this._updateView();
      });
  }

  @Input()
  set pygPermissionsThen(templateRef: TemplateRef<Context> | null) {
    this._thenTemplateRef = templateRef;
    this._thenViewRef = null; // clear previous view if any.
    this._updateView();
  }

  @Input()
  set pygPermissionsElse(templateRef: TemplateRef<Context> | null) {
    this._elseTemplateRef = templateRef;
    this._elseViewRef = null; // clear previous view if any.
    this._updateView();
  }

  private _updateView() {
    if (this._context.hasPermissions) {
      if (!this._thenViewRef) {
        this._viewContainer.clear();
        this._elseViewRef = null;
        if (this._thenTemplateRef) {
          this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef, this._context);
        }
      }
    } else {
      if (!this._elseViewRef) {
        this._viewContainer.clear();
        this._thenViewRef = null;
        if (this._elseTemplateRef) {
          this._elseViewRef = this._viewContainer.createEmbeddedView(this._elseTemplateRef, this._context);
        }
      }
    }
  }

  private permissionsCheck(userInfo: UserInfo, permissions: string) {
    if (permissions === 'admin' && userInfo?.adminAccess === true) return true;
    // TODO: rozsirit o ostatne, ked bude treba

    return false;
  }
}
