import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private dialog: MatDialogRef<any>;

  constructor(private matDialog: MatDialog) {}

  open(component: any, width: string, data?: MatDialogConfig): MatDialogRef<any> {
    this.dialog = this.matDialog.open(component, data);
    this.dialog.updateSize(width, 'auto');

    return this.dialog;
  }

  close() {
    this.dialog.close();
    this.dialog = null;
  }
}
