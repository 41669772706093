import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { setLoading } from '@datorama/akita';
import { environment } from 'apps/trix/src/environments/environment';
import { EMPTY, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { getErrorMessage } from '../../../shared/get-error-mesage';
import { MetricGroupQuery } from './metric-group.query';
import { MetricGroupsStore } from './metric-groups.store';

@Injectable()
export class MetricGroupService {
  constructor(
    private groupStore: MetricGroupsStore,
    private groupQuery: MetricGroupQuery,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {}

  getAll() {
    return this.groupQuery.selectHasCache().pipe(
      switchMap(hasCache => {
        const request$ = this.http.get<any>(`${environment.adminApi}/metric-groups`).pipe(
          setLoading(this.groupStore),
          tap(groups => {
            const data = Object.keys(groups).map(name => {
              return {
                name,
                metrics:
                  groups[name].metrics?.map(x => ({
                    name: x.metric,
                    isExportable: x['data-export'] ?? false
                  })) ?? []
              };
            });

            this.groupStore.set(data);
          }),
          catchError(err => of(this.snackBar.open(getErrorMessage(err), 'OK', { panelClass: 'red-snackbar' })))
        );

        return hasCache && this.groupQuery.getCount() ? EMPTY : request$;
      })
    );
  }

  refreshCache() {
    this.groupStore.setHasCache(false);
  }
}
