import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { setLoading } from '@datorama/akita';
import { environment } from '../../../environments/environment';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { UserMetricGroupStore } from './user-metric-groups.store';
import { UserMetricGroupQuery } from './user-metric-group.query';
import { getErrorMessage } from '../../modules/shared/get-error-mesage';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class UserMetricGroupService {
  constructor(
    private umgStore: UserMetricGroupStore,
    private umgQuery: UserMetricGroupQuery,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {}

  getAll() {
    return this.umgQuery.selectHasCache().pipe(
      take(1),
      switchMap(hasCache => {
        const request$ = this.http.get<string[]>(`${environment.directoryApi}/user/metric_groups`).pipe(
          setLoading(this.umgStore),
          tap(x => this.umgStore.set(x.map(y => ({ id: y })))),
          catchError(err => of(this.snackBar.open(getErrorMessage(err), 'OK', { panelClass: 'red-snackbar' })))
        );

        return hasCache && this.umgStore.getValue() ? EMPTY : request$;
      })
    );
  }

  refreshCache() {
    this.umgStore.setHasCache(false);
  }
}
