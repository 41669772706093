import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface UserMetricGroupState extends EntityState<{ id: string }, string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'user-metric-group'
})
export class UserMetricGroupStore extends EntityStore<UserMetricGroupState> {
  constructor() {
    super();
  }
}
