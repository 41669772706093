export const environment = {
  production: true,
  development: false,
  isDemo: false,
  adminApi: 'https://orion.pygmalios.com/api',
  authApi: 'https://auth.api.pygmalios.com/v1',
  metricsApi: 'https://reporting.api.pygmalios.com/v1/reporting/core',
  metricsDefs: 'https://reporting.api.pygmalios.com/v1/reporting/definitions',
  directoryApi: 'https://reporting.api.pygmalios.com/v1/directory',
  exportsApi: 'https://reporting.api.pygmalios.com/v1/data_exports',
  streamingApi: 'streaming.api.pygmalios.com',
  semaphoreApi: 'https://semaphore.pygmalios.com',
  savedExplores: 'https://reporting.api.pygmalios.com/v1/saved_explores',

  clientId: 'gA5zL9PgmNX4_MIAFBcqSQfGhrfFKvRS6GhpY-VmbHU',
  sentry: 'https://75396479681145acad41fa406e81e6a6@o30372.ingest.sentry.io/5409000',
  ga: 'G-LJZPN17H54'
};
