import * as moment from 'moment';
import { environment } from '../environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { AuthService } from './services/auth.service';
import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserInfoService } from './services/user-info/user-info.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrganizationTreeService } from './services/organization-tree/organization-tree.service';
import { UserInfoQuery } from './services/user-info/user-info.query';
import { ThemeService } from './modules/shared/theme.service';
import { combineLatest } from 'rxjs';
import { OrganizationTreeQuery } from './services/organization-tree/organization-tree.query';
import { inNextTick } from './modules/shared/future';
import { UserMetricGroupQuery } from './services/user-metric-group/user-metric-group.query';
import { UserMetricGroupService } from './services/user-metric-group/user-metric-group.service';
import { SidebarService } from './modules/shared/sidebar.service';
import { OrganizationTreeStore } from './services/organization-tree/organizations-tree.store';
import { UserInfoStore } from './services/user-info/user-info.store';
import { UserMetricGroupStore } from './services/user-metric-group/user-metric-groups.store';
import * as Sentry from '@sentry/angular-ivy';

@UntilDestroy()
@Component({
  selector: 'pyg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public submoduleMenu: TemplateRef<any>;

  isLoaded = false;
  theme: string;

  get isInAdminConsole() {
    return this.router.url.startsWith('/admin');
  }

  @ViewChild('submenu', { read: ViewContainerRef }) submenu: ViewContainerRef;

  constructor(
    public authService: AuthService,
    private swUpdate: SwUpdate,
    private themeService: ThemeService,
    infoQuery: UserInfoQuery,
    infoService: UserInfoService,
    private infoStore: UserInfoStore,
    orgTreeService: OrganizationTreeService,
    orgTreeQuery: OrganizationTreeQuery,
    private orgTreeStore: OrganizationTreeStore,
    umgQuery: UserMetricGroupQuery,
    umgService: UserMetricGroupService,
    private umgStore: UserMetricGroupStore,
    private router: Router,
    public sidebar: SidebarService
  ) {
    infoService.getUserInfo().pipe(untilDestroyed(this)).subscribe();
    orgTreeService.getAll().pipe(untilDestroyed(this)).subscribe();
    umgService.getAll().pipe(untilDestroyed(this)).subscribe();

    combineLatest([infoQuery.selectLoading(), orgTreeQuery.selectLoading(), umgQuery.selectLoading()])
      .pipe(untilDestroyed(this))
      .subscribe(([info, tree, umg]) => {
        this.isLoaded = !(info || tree || umg);
      });

    infoQuery.theme$.pipe(untilDestroyed(this)).subscribe(theme => {
      this.theme = theme ?? 'pygmalios';
      this.themeService.setTheme(this.theme);
    });

    infoQuery.select().subscribe(x => {
      if (!x) {
        Sentry.setUser(null);
        return;
      }

      Sentry.setUser({
        id: x.id,
        username: x.firstName + ' ' + x.lastName,
        email: x.email
      });
    });

    authService.isLogged$.subscribe(isLogged => {
      if (!isLogged) {
        router.navigateByUrl('/auth/login');
      }
    });

    this.printStop();
    this.refreshOnNewDay();
  }

  async onActivate(event) {
    await inNextTick();
    this.submoduleMenu = event.menu;
    this.submenu?.clear();

    if (this.submoduleMenu) {
      this.submenu.createEmbeddedView(this.submoduleMenu, null);
    }
  }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe(event => {
        console.log('[Service-worker update] Event: ', event.type);
        if (event.type === 'VERSION_READY') {
          this.swUpdate.activateUpdate().then(() => window.location.reload());
        }
      });
    }
  }

  logout() {
    this.authService.logout();
  }

  private printStop() {
    if (!environment.production) {
      return;
    }

    const font = 'font-family:helvetica; font-size:20px; ';
    const large = 'font-size:50px; font-weight:bold; color:red; -webkit-text-stroke:1px black;';

    console.log('%cStop!', font + large);
    console.log(
      '%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable any feature or "hack" someone\'s account, it is a scam and will give them access to your account.',
      font
    );
  }

  private refreshOnNewDay() {
    const now = moment();
    const tomorrow = now.clone().add(1, 'day').startOf('day');
    const diff = moment.duration(tomorrow.diff(now)).asMilliseconds();

    setTimeout(() => {
      this.orgTreeStore.reset();
      this.infoStore.reset();
      this.umgStore.reset();
      window.location.reload();
    }, diff);
  }

  openSidebar() {
    this.sidebar.isOpen = true;
  }

  closeSidebar() {
    this.sidebar.isOpen = false;
  }

  tooglePinSidebar() {
    this.sidebar.isPin = !this.sidebar.isPin;
    this.sidebar.isOpen = this.sidebar.isPin;
  }
}
