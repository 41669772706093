import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AdminGuard } from './services/admin-guard';
import { AuthenticationGuard } from './services/authentication-guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/authentication/authentication.module').then(x => x.AuthenticationModule)
  },
  {
    path: '',
    component: AppComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        redirectTo: '/analytics/visits',
        pathMatch: 'full'
      },
      {
        path: 'admin',
        canActivate: [AuthenticationGuard, AdminGuard],
        loadChildren: () => import('./modules/admin/admin.module').then(x => x.AdminModule)
      },
      {
        path: 'analytics',
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./modules/analytics/analytics.module').then(x => x.AnalyticsModule)
      },
      { path: '**', component: NotFoundComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
