import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { OrganizationTreeState, OrganizationTreeStore } from './organizations-tree.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: 'name',
  sortByOrder: Order.ASC
})
export class OrganizationTreeQuery extends QueryEntity<OrganizationTreeState> {
  constructor(protected store: OrganizationTreeStore) {
    super(store);
  }

  getOperation(id: string) {
    this.getAll().forEach(x => {
      const op = x.operations.filter(y => y.id === id);
      if (op) {
        return op[0];
      }
    });

    return null;
  }
}
