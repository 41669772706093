import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { UserInfo } from './user-info';

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'user-info',
  cache: {
    ttl: 60 * 1000 // 1 min
  }
})
export class UserInfoStore extends Store<UserInfo> {
  constructor() {
    super({});
  }
}
