import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { MetricGroup } from './metric-group';

export interface MetricGroupsState extends EntityState<MetricGroup, string> {}

@Injectable()
@StoreConfig({
  idKey: 'name',
  name: 'metric-group',
  cache: {
    ttl: 60 * 60 * 1000 // 1 hour
  }
})
export class MetricGroupsStore extends EntityStore<MetricGroupsState> {
  constructor() {
    super();
  }
}
