import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidebarService {
  private sidebarOpen = new BehaviorSubject<boolean>(true);
  private sidebarPin = new BehaviorSubject<boolean>(true);

  get isOpen() {
    return this.sidebarOpen.value || this.sidebarPin.value;
  }

  get isPin() {
    return this.sidebarPin.value;
  }

  get $sidebarOpen() {
    return this.sidebarOpen.asObservable();
  }

  set isOpen(open: boolean) {
    this.sidebarOpen.next(open);
  }

  set isPin(pin: boolean) {
    this.sidebarPin.next(pin);
  }
}
