import { Expose, Type } from 'class-transformer';

export class Organization {
  @Expose({ name: '_id' })
  id: string;

  @Expose()
  name: string;

  @Expose()
  @Type(() => Operation)
  operations: Operation[];
}

export class Operation {
  @Expose({ name: '_id' })
  id?: string;

  @Expose()
  name: string;
}
