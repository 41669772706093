import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { UserMetricGroupState, UserMetricGroupStore } from './user-metric-groups.store';

@Injectable({ providedIn: 'root' })
export class UserMetricGroupQuery extends QueryEntity<UserMetricGroupState> {
  constructor(protected store: UserMetricGroupStore) {
    super(store);
  }

  hasAvailableMetricGroup(metricGroup: string): boolean {
    return this.getAll().some(x => x.id === metricGroup);
  }

  hasAnyAvailableMetricGroup(metricGroups: string[]): boolean {
    for (const x of this.getAll()) {
      if (metricGroups.includes(x.id)) {
        return true;
      }
    }

    return false;
  }
}
