import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { setLoading } from '@datorama/akita';
import { environment } from '../../../environments/environment';
import { Organization } from './organization';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { EMPTY, of } from 'rxjs';
import { OrganizationTreeStore } from './organizations-tree.store';
import { OrganizationTreeQuery } from './organization-tree.query';
import { getErrorMessage } from '../../modules/shared/get-error-mesage';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class OrganizationTreeService {
  constructor(
    private orgStore: OrganizationTreeStore,
    private orgQuery: OrganizationTreeQuery,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {}

  getAll() {
    return this.orgQuery.selectHasCache().pipe(
      switchMap(hasCache => {
        const request$ = this.http.get<any[]>(`${environment.directoryApi}/user/shallow_organizations_tree`).pipe(
          setLoading(this.orgStore),
          map(x => plainToClass(Organization, x, { excludeExtraneousValues: true })),
          tap(x => this.orgStore.set(x)),
          catchError(err => of(this.snackBar.open(getErrorMessage(err), 'OK', { panelClass: 'red-snackbar' })))
        );

        return hasCache && this.orgQuery.getValue() ? EMPTY : request$;
      })
    );
  }

  refreshCache() {
    this.orgStore.setHasCache(false);
  }
}
